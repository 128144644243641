import Axios from "axios";
let token = window.localStorage.getItem("accessToken") || "";

const axios = Axios.create({
    baseURL:
        process.env.NODE_ENV === "development"
            ? `http://192.168.0.242:3680/api/v1`
            : `https://api-demo.techobin.com/api/v1`,

    headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    },
});

export default axios;
